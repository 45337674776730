import React, {useContext} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AppContext} from "../_context/AppState";
import {AlertType, PlanType} from "../_context/AppTypes";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import UserService from "../_services/user.service";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {ReportModal} from '../_constants/const';

interface Column {
    id: 'orderId' | 'planName' | 'roadmapDeviceName' | 'unixtime' | 'detect' | 'address';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
    isLinkAddr?: () => boolean;
}

const columns: Column[] = [
    {
        id: 'orderId',
        label: 'No',
        align: 'right',
    },
    {id: 'planName', label: 'Plan', minWidth: 150},
    {id: 'roadmapDeviceName', label: 'Vehicle'},
    {
        id: 'unixtime',
        label: 'At',
        format: (value: number) => moment.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
    {id: 'detect', label: 'Detect'},
    {
        id: 'address',
        label: 'Address',
        isLinkAddr: () => true
    },
];


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        container: {
            minHeight: '80vh',
            maxHeight: '80vh',
        },
        dateContainer: {
            width: '140px',
        },
        reportControlItem: {
            margin: '10px',
        },
        reportControl: {
            flexGrow: 1,
        },
        reportSelectPlan: {
            width: '300px',
        }
    }),
);

export default function AlertReportDialogs() {
    const classes = useStyles();

    const {state, alertReportModal, setAlertReportModal} = useContext(AppContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataReport, setDataReport] = React.useState<AlertType[]>([]);

    const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(new Date());
    const [selectedFinishDate, setSelectedFinishDate] = React.useState<Date | null>(new Date());

    const handleStartDateChange = (date: Date | null) => {
        setSelectedStartDate(date);
    };
    const handleFinishDateChange = (date: Date | null) => {
        setSelectedFinishDate(date);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setAlertReportModal(ReportModal.NOT_OPEN);
    };

    const handleReport = (planID: Number) => {
        if (planID > ReportModal.NOT_OPEN) {
            UserService.getAlertReport({
                planIds: [planID],
                startDay: moment(selectedStartDate).format("yyyy-MM-DD"),
                endDay: moment(selectedFinishDate).format("yyyy-MM-DD")
            }).then(
                (data) => {
                    setDataReport(data);
                },
                (error) => {
                    console.log("UserService.getReport():", error)
                }
            );
        }
    }

    const handleChangePlan = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAlertReportModal(event.target.value as number);
    };

    React.useEffect(() => {
        handleReport(alertReportModal);

        return () => {
            setDataReport([]);
        };

    }, [alertReportModal]);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={alertReportModal == ReportModal.NOT_OPEN ? false : true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" className={classes.reportControl}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                        <Grid item>
                            <FormControl className={classes.reportControlItem}>
                                <InputLabel id="plan-report-select-label">Alert Report</InputLabel>
                                <Select
                                    labelId="plan-report-select-label"
                                    id="plan-report-select"
                                    value={alertReportModal}
                                    onChange={handleChangePlan}
                                    className={classes.reportSelectPlan}
                                >{
                                    state.plans.map((plan: PlanType) => <MenuItem key={plan.id}
                                                                                  value={plan.id}>{plan.name}</MenuItem>)
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.reportControlItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="none"
                                    id="date-picker-start"
                                    label="Start Day"
                                    format="yyyy-MM-dd"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    className={classes.dateContainer}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item className={classes.reportControlItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="none"
                                    id="date-picker-finish"
                                    label="Finish Day"
                                    format="yyyy-MM-dd"
                                    value={selectedFinishDate}
                                    onChange={handleFinishDateChange}
                                    className={classes.dateContainer}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item className={classes.reportControlItem}>
                            <Button variant="outlined" color="primary"
                                    onClick={() => handleReport(alertReportModal)}>Report</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.orderId}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    let valueLatLng = "";
                                                    if (column.id == 'address') {
                                                        valueLatLng = row['latLng'];
                                                    }

                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : (column.isLinkAddr ?
                                                                <Link target="_blank"
                                                                      href={`https://www.google.com/maps?q=${valueLatLng}&hl=vi&ie=UTF8&z=17`}>{value}</Link> : value)}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={dataReport.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
