(function () {
    'use strict';

    var L = require('leaflet');

    module.exports = L.Control.extend({
        options: {
            header: 'Routing error',
            formatMessage: function (error) {
                if (error.status < 0) {
                    return 'Calculating the route caused an error. Technical description follows: <code><pre>' +
                        error.message + '</pre></code';
                } else {
                    return 'The route could not be calculated. ' +
                        error.message;
                }
            }
        },

        initialize: function (routingControl, options) {
            L.Control.prototype.initialize.call(this, options);
            routingControl
                .on('routingerror', L.bind(function (e) {
                    if (this._element) {
                        this._element.children[1].innerHTML = this.options.formatMessage(e.error);
                        this._element.style.visibility = 'visible';
                    }
                }, this))
                .on('routingstart', L.bind(function () {
                    if (this._element) {
                        this._element.style.visibility = 'hidden';
                    }
                }, this));
        },

        onAdd: function () {
            var header,
                message;

            this._element = L.DomUtil.create('div', 'leaflet-bar leaflet-routing-error');
            this._element.style.visibility = 'hidden';

            header = L.DomUtil.create('h3', null, this._element);
            message = L.DomUtil.create('span', null, this._element);

            header.innerHTML = this.options.header;

            return this._element;
        },

        onRemove: function () {
            delete this._element;
        }
    });
})();
