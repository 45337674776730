import L from 'leaflet';
import {getJSON} from '../util';

export var Photon = L.Class.extend({
    options: {
        serviceUrl: 'https://photon.komoot.de/api/',
        reverseUrl: 'https://photon.komoot.de/reverse/',
        nameProperties: ['name', 'street', 'suburb', 'hamlet', 'town', 'city', 'state', 'country']
    },

    initialize: function (options) {
        L.setOptions(this, options);
    },

    geocode: function (query, cb, context) {
        var params = L.extend(
            {
                q: query
            },
            this.options.geocodingQueryParams
        );

        getJSON(
            this.options.serviceUrl,
            params,
            L.bind(function (data) {
                cb.call(context, this._decodeFeatures(data));
            }, this)
        );
    },

    suggest: function (query, cb, context) {
        return this.geocode(query, cb, context);
    },

    reverse: function (latLng, scale, cb, context) {
        var params = L.extend(
            {
                lat: latLng.lat,
                lon: latLng.lng
            },
            this.options.reverseQueryParams
        );

        getJSON(
            this.options.reverseUrl,
            params,
            L.bind(function (data) {
                cb.call(context, this._decodeFeatures(data));
            }, this)
        );
    },

    _decodeFeatures: function (data) {
        var results = [],
            i,
            f,
            c,
            latLng,
            extent,
            bbox;

        if (data && data.features) {
            for (i = 0; i < data.features.length; i++) {
                f = data.features[i];
                c = f.geometry.coordinates;
                latLng = L.latLng(c[1], c[0]);
                extent = f.properties.extent;

                if (extent) {
                    bbox = L.latLngBounds([extent[1], extent[0]], [extent[3], extent[2]]);
                } else {
                    bbox = L.latLngBounds(latLng, latLng);
                }

                results.push({
                    name: this._decodeFeatureName(f),
                    html: this.options.htmlTemplate ? this.options.htmlTemplate(f) : undefined,
                    center: latLng,
                    bbox: bbox,
                    properties: f.properties
                });
            }
        }

        return results;
    },

    _decodeFeatureName: function (f) {
        return (this.options.nameProperties || [])
            .map(function (p) {
                return f.properties[p];
            })
            .filter(function (v) {
                return !!v;
            })
            .join(', ');
    }
});

export function photon(options) {
    return new Photon(options);
}
