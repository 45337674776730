import React, {useContext} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AppContext} from "../_context/AppState";
import {ReportModal} from '../_constants/const';
import {Typography} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        container: {
            minHeight: '80vh',
            maxHeight: '80vh',
        },
        dateContainer: {
            width: '140px',
        },
        reportControlItem: {
            margin: '10px',
        },
        reportControl: {
            flexGrow: 1,
        },
        reportSelectPlan: {
            width: '300px',
        },
    }),
);

export default function PlanReportDialogs() {
    const classes = useStyles();
    const {settingModal, setSettingModal} = useContext(AppContext);

    const handleClose = () => {
        setSettingModal(ReportModal.NOT_OPEN);
    };

    React.useEffect(() => {
        return () => {
        };

    }, [settingModal]);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={settingModal == 0 ? false : true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" className={classes.reportControl}>
                    Setting
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>User can confing email/SMS for alert event!</Typography>
                        <Typography>IN PROCESS...</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
