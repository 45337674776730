import React from 'react';
import MainApp from './App/MainApp';
import {AppProvider} from './_context/AppState';

import './App.css';

const App: React.FC = () => {
    return (
        <AppProvider>
            <MainApp/>
        </AppProvider>
    )
}

export default App;
