import React, {useContext, useState} from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import UserService from "../_services/user.service";
import {AlertType} from '../_context/AppTypes';
import moment from "moment";
import {useInterval} from "../_hook/useInterval";
import L from 'leaflet';
import {AppContext} from "../_context/AppState";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
        },
        notification: {
            position: "absolute",
            zIndex: 11111,
            right: 40,
        },
        typography: {
            padding: theme.spacing(2),
        },
        notiContent: {
            width: 328,
            maxHeight: 448,
            backgroundColor: theme.palette.background.paper,
        }
    }),
);

interface State {
    startingpoint: string;
    destination: string;
}

const Notification: React.FC = () => {
    const classes = useStyles();
    const {mapRef} = useContext(AppContext)
    const [countAlerts, setCountAlerts] = useState(0);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [listAlert, setListAlerts] = useState<AlertType[]>([]);

    const [alertChecked, setAlertChecked] = React.useState([0]);

    const [alertMarkers, setAlertMarkers] = useState<Map<number, L.Marker>>(new Map<number, L.Marker>());

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        UserService.getAlerts().then((data) => {
                let innerListAlert: AlertType[] = [];
                for (const item of data) {
                    innerListAlert.push(item);
                }
                setListAlerts(innerListAlert);

                // Set badger Alert ICON
                if (countAlerts > 0 && innerListAlert && innerListAlert.length > 0) {
                    // @ts-ignore
                    UserService.hasReadAlerts({"data": innerListAlert}).then((data) => {
                            setCountAlerts(0);
                        },
                        (error) => {
                            console.log("UserService.hasReadAlerts():", error);
                        });
                }
                ;
            },
            (error) => {
                console.log("UserService.getAlerts():", error);
            }
        );

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickAlert = (item: AlertType) => {
        const currentIndex = alertChecked.indexOf(item.id);
        const newAlertChecked = [...alertChecked];

        if (currentIndex === -1) {
            // Not click
            newAlertChecked.push(item.id);

            if (item.latLng) {
                const latLng = item.latLng.split(",");
                const marker = L.marker([parseFloat(latLng[0]), parseFloat(latLng[1])]);
                marker.bindTooltip(`Plan:${item.planName}<br/>Vehicle:<b>${item.roadmapDeviceName}</b> Detect:<b>${item.detect}</b><br/>At:${moment.unix(item.unixtime).format("YYYY-MM-DD HH:mm:ss")}`, {
                    permanent: true,
                }).openTooltip();

                marker.addTo(mapRef.current);
                mapRef.current.setView(marker.getLatLng(), 17);

                // Store ref
                setAlertMarkers(new Map(alertMarkers.set(item.id, marker)));
            }
        } else {
            // Has clicked
            newAlertChecked.splice(currentIndex, 1);
            // Remove marker from map
            const marker = alertMarkers.get(item.id);
            if (marker)
                marker.remove();

            //TODO remove marker from Map
            setAlertMarkers((prev) => {
                const newState = new Map(prev);
                newState.delete(item.id);
                return newState;
            });
        }

        setAlertChecked(newAlertChecked);
    }

    const openNoti = Boolean(anchorEl);
    const ipPopNoti = openNoti ? 'simple-popover' : undefined;

    useInterval(() => {

        UserService.getCountAlerts().then((data) => {
                if (data && data > 0)
                    setCountAlerts(data);
            },
            (error) => {
                console.log("UserService.getStatusVehicles():", error);
            }
        );
    }, 5000);

    return <div className={classes.notification}>
        <IconButton aria-label="show new notifications" color="inherit" onClick={handleClick}>
            {countAlerts > 0 ? <Badge badgeContent={countAlerts} color="secondary">
                <NotificationsIcon/>
            </Badge> : <NotificationsIcon/>}
        </IconButton>
        <Popover
            id={ipPopNoti}
            open={openNoti}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List component="nav" dense={true} className={classes.notiContent}
                  subheader={<ListSubheader component="div" id="nested-list-subheader"> Notifications</ListSubheader>
                  }>
                {listAlert.map(item => <ListItem button key={item.id} onClick={() => handleClickAlert(item)}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={alertChecked.indexOf(item.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{'aria-labelledby': `checkbox-list-label-${item.id}`}}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={`Plan ${item.planName} Vehicle ${item.roadmapDeviceName} Detected: ${item.detect}`}
                        secondary={`At time: ${moment.unix(item.unixtime).format("YYYY-MM-DD HH:mm:ss")} `}/>
                </ListItem>)}
            </List>
        </Popover>
    </div>
}

export default Notification;