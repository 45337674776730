import axios from "axios";
import {API, BuildApi} from "../_constants/apis"

const login = (username, password) => {
    return axios
        .post(BuildApi(API.Login), {
            username,
            password,
        })
        .then((response) => {
            if (response.data.error) {
                throw new Error(response.data.error.message);
            }

            return response.data.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    login,
    logout,
    getCurrentUser,
};
