export const RoutingMachineID = "routingMachine";
export const KeyDeviceID = "_device_id";
export const DistanceGeofence = 300; //meter
export const TokenMB = "pk.eyJ1IjoidG9hbnFuZ3V5ZW4iLCJhIjoiY2tmeXQzMjh1MjZqYjJybXRqZmI1ZnN1YiJ9.eSfhb-AF53dm85oWokvQ5w";
export const TokenGG = "AIzaSyA0CeQZXT-JA1YD-c6GBpgnVCT2EwNUt3w" //from QD77 billus

export enum ReportModal {
    NOT_OPEN = 0,
    OPEN_NO_PLAN = -1, // not select planID
}
