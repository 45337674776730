import React, {useContext, useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StreetviewIcon from '@material-ui/icons/Streetview';

import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from "leaflet";
import {AppContext} from "../_context/AppState";
import {PlanGeoException, Types} from "../_context/AppTypes";
import * as geojson from "geojson";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '400px',
        },
    }),
);

const GeoException: React.FC = () => {
    const classes = useStyles();
    const {state, dispatch, mapRef, drawnItemsRef} = useContext(AppContext);

    const [planGeo, setPlanGeo] = React.useState<PlanGeoException[]>(function () {
        if (state.currentPlan.planGeoExceptions)
            return state.currentPlan.planGeoExceptions;
        return [];
    });


    useEffect(() => {
        if (planGeo.length > 0) {
            let tmpPlangeo: PlanGeoException[] = [];

            for (let geo of planGeo) {
                // {"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[106.662483,10.783396]}}
                // @ts-ignore
                L.geoJSON(geo.geoJson, {
                    pointToLayer: function (feature: geojson.Feature, latlng: L.LatLng) {
                        if (feature.properties && feature.properties.radius) {
                            return new L.Circle(latlng, feature.properties.radius);
                        }
                    },
                    onEachFeature: function (feature: geojson.Feature, layer: L.Layer) {
                        if (geo.id === 0) {
                            drawnItemsRef.current.addLayer(layer);
                            // Update LayerID
                            geo.id = drawnItemsRef.current.getLayerId(layer);
                            tmpPlangeo.push(geo);
                        }
                    }
                });
            }
            if (tmpPlangeo.length > 0)
                setPlanGeo(tmpPlangeo);
        }
        ;

        // @ts-ignore
        mapRef.current.on(L.Draw.Event.CREATED, function (event) {
            var layer = event.layer;
            if (drawnItemsRef.current) {
                drawnItemsRef.current.addLayer(layer);

                const newItem = {id: drawnItemsRef.current.getLayerId(layer), geoJson: layer.toGeoJSON()};
                if (typeof layer.getRadius === 'function')
                    newItem.geoJson.properties.radius = layer.getRadius();

                const tmpPlangeo = [...planGeo, newItem];

                dispatch({
                    type: Types.UpdatePlan, payload: {
                        planGeoExceptions: tmpPlangeo,
                    }
                });
                setPlanGeo(tmpPlangeo);

            }
        });

        // @ts-ignore
        mapRef.current.on(L.Draw.Event.EDITED, function (event) {
            let layers = event.layers;
            let tmpPlangeo: PlanGeoException[] = [...planGeo];

            layers.eachLayer(function (layer: any) {
                tmpPlangeo.map(veh => {
                    if (veh.id === drawnItemsRef.current.getLayerId(layer)) {
                        veh.geoJson = layer.toGeoJSON();
                        if (typeof layer.getRadius === 'function') {
                            // @ts-ignore
                            veh.geoJson.properties.radius = layer.getRadius();
                        }
                    }
                })
            });

            dispatch({
                type: Types.UpdatePlan, payload: {
                    planGeoExceptions: tmpPlangeo,
                }
            });
            setPlanGeo(tmpPlangeo);
        });

        // @ts-ignore
        mapRef.current.on(L.Draw.Event.DELETED, function (event) {
            let layers = event.layers;
            let tmpPlangeo: PlanGeoException[] = [];

            layers.eachLayer(function (layer: any) {
                tmpPlangeo = [...planGeo.filter(veh => veh.id !== drawnItemsRef.current.getLayerId(layer))];
            });

            // TODO: reducer currentPlan NOT array of plan
            dispatch({
                type: Types.UpdatePlan, payload: {
                    planGeoExceptions: tmpPlangeo,
                }
            });
            setPlanGeo(tmpPlangeo);
        });


        return () => {
            // @ts-ignore
            mapRef.current.off(L.Draw.Event.CREATED);
            // @ts-ignore
            mapRef.current.off(L.Draw.Event.DELETED);
            // @ts-ignore
            mapRef.current.off(L.Draw.Event.EDITED);
        }
    }, [planGeo]);


    return <div className={classes.root}>
        <Typography variant="h6" gutterBottom>Safe Zone</Typography>
        <List dense={true}>
            {planGeo.map((item) => {
                if (item.id > 0) {
                    return <ListItem key={item.id}>
                        <ListItemIcon>
                            <StreetviewIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={"ID: " + item.id}
                        />
                    </ListItem>
                }
            })}
        </List>
    </div>
}

export default GeoException;