import L, {DivIcon} from "leaflet";

export const GetVehicleIconByAngle = (angle: number | undefined): DivIcon => {
    if (angle == undefined)
        angle = 1;

    return L.divIcon({
        className: `redcar-sprite-car_${angle}`,
        iconSize: [60, 60],
        iconAnchor: [30, 30]
    });
}