(function () {
    'use strict';

    var L = require('leaflet');

    module.exports = L.Class.extend({
        options: {
            containerClassName: ''
        },

        initialize: function (options) {
            L.setOptions(this, options);
        },

        createContainer: function (className) {
            var table = L.DomUtil.create('table', (className || '') + ' ' + this.options.containerClassName),
                colgroup = L.DomUtil.create('colgroup', '', table);

            L.DomUtil.create('col', 'leaflet-routing-instruction-icon', colgroup);
            L.DomUtil.create('col', 'leaflet-routing-instruction-text', colgroup);
            L.DomUtil.create('col', 'leaflet-routing-instruction-distance', colgroup);

            return table;
        },

        createStepsContainer: function () {
            return L.DomUtil.create('tbody', '');
        },

        createStep: function (text, distance, icon, steps) {
            var row = L.DomUtil.create('tr', '', steps),
                span,
                td;
            td = L.DomUtil.create('td', '', row);
            span = L.DomUtil.create('span', 'leaflet-routing-icon leaflet-routing-icon-' + icon, td);
            td.appendChild(span);
            td = L.DomUtil.create('td', '', row);
            td.appendChild(document.createTextNode(text));
            td = L.DomUtil.create('td', '', row);
            td.appendChild(document.createTextNode(distance));
            return row;
        }
    });
})();
