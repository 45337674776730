/* 
   NOTICE
   Since version 3.2.5, the functionality in this file is by
   default NOT used for localizing OSRM instructions.
   Instead, we rely on the module osrm-text-instructions (https://github.com/Project-OSRM/osrm-text-instructions/).
   
   This file can still be used for other routing backends, or if you specify the
   stepToText option in the OSRMv1 class.
*/

(function () {
    'use strict';

    var L = require('leaflet');
    var spanish = {
        directions: {
            N: 'norte',
            NE: 'noreste',
            E: 'este',
            SE: 'sureste',
            S: 'sur',
            SW: 'suroeste',
            W: 'oeste',
            NW: 'noroeste',
            SlightRight: 'leve giro a la derecha',
            Right: 'derecha',
            SharpRight: 'giro pronunciado a la derecha',
            SlightLeft: 'leve giro a la izquierda',
            Left: 'izquierda',
            SharpLeft: 'giro pronunciado a la izquierda',
            Uturn: 'media vuelta'
        },
        instructions: {
            // instruction, postfix if the road is named
            'Head':
                ['Derecho {dir}', ' sobre {road}'],
            'Continue':
                ['Continuar {dir}', ' en {road}'],
            'TurnAround':
                ['Dar vuelta'],
            'WaypointReached':
                ['Llegó a un punto del camino'],
            'Roundabout':
                ['Tomar {exitStr} salida en la rotonda', ' en {road}'],
            'DestinationReached':
                ['Llegada a destino'],
            'Fork': ['En el cruce gira a {modifier}', ' hacia {road}'],
            'Merge': ['Incorpórate {modifier}', ' hacia {road}'],
            'OnRamp': ['Gira {modifier} en la salida', ' hacia {road}'],
            'OffRamp': ['Toma la salida {modifier}', ' hacia {road}'],
            'EndOfRoad': ['Gira {modifier} al final de la carretera', ' hacia {road}'],
            'Onto': 'hacia {road}'
        },
        formatOrder: function (n) {
            return n + 'º';
        },
        ui: {
            startPlaceholder: 'Inicio',
            viaPlaceholder: 'Via {viaNumber}',
            endPlaceholder: 'Destino'
        },
        units: {
            meters: 'm',
            kilometers: 'km',
            yards: 'yd',
            miles: 'mi',
            hours: 'h',
            minutes: 'min',
            seconds: 's'
        }
    };

    L.Routing = L.Routing || {};

    var Localization = L.Class.extend({
        initialize: function (langs) {
            this._langs = L.Util.isArray(langs) ? langs.slice() : [langs, 'en'];

            for (var i = 0, l = this._langs.length; i < l; i++) {
                var generalizedCode = /([A-Za-z]+)/.exec(this._langs[i])[1]
                if (!Localization[this._langs[i]]) {
                    if (Localization[generalizedCode]) {
                        this._langs[i] = generalizedCode;
                    } else {
                        throw new Error('No localization for language "' + this._langs[i] + '".');
                    }
                }
            }
        },

        localize: function (keys) {
            var dict,
                key,
                value;

            keys = L.Util.isArray(keys) ? keys : [keys];

            for (var i = 0, l = this._langs.length; i < l; i++) {
                dict = Localization[this._langs[i]];
                for (var j = 0, nKeys = keys.length; dict && j < nKeys; j++) {
                    key = keys[j];
                    value = dict[key];
                    dict = value;
                }

                if (value) {
                    return value;
                }
            }
        }
    });

    module.exports = L.extend(Localization, {
        'en': {
            directions: {
                N: 'north',
                NE: 'northeast',
                E: 'east',
                SE: 'southeast',
                S: 'south',
                SW: 'southwest',
                W: 'west',
                NW: 'northwest',
                SlightRight: 'slight right',
                Right: 'right',
                SharpRight: 'sharp right',
                SlightLeft: 'slight left',
                Left: 'left',
                SharpLeft: 'sharp left',
                Uturn: 'Turn around'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Head {dir}', ' on {road}'],
                'Continue':
                    ['Continue {dir}'],
                'TurnAround':
                    ['Turn around'],
                'WaypointReached':
                    ['Waypoint reached'],
                'Roundabout':
                    ['Take the {exitStr} exit in the roundabout', ' onto {road}'],
                'DestinationReached':
                    ['Destination reached'],
                'Fork': ['At the fork, turn {modifier}', ' onto {road}'],
                'Merge': ['Merge {modifier}', ' onto {road}'],
                'OnRamp': ['Turn {modifier} on the ramp', ' onto {road}'],
                'OffRamp': ['Take the ramp on the {modifier}', ' onto {road}'],
                'EndOfRoad': ['Turn {modifier} at the end of the road', ' onto {road}'],
                'Onto': 'onto {road}'
            },
            formatOrder: function (n) {
                var i = n % 10 - 1,
                    suffix = ['st', 'nd', 'rd'];

                return suffix[i] ? n + suffix[i] : n + 'th';
            },
            ui: {
                startPlaceholder: 'Start',
                viaPlaceholder: 'Via {viaNumber}',
                endPlaceholder: 'End'
            },
            units: {
                meters: 'm',
                kilometers: 'km',
                yards: 'yd',
                miles: 'mi',
                hours: 'h',
                minutes: 'min',
                seconds: 's'
            }
        },

        'de': {
            directions: {
                N: 'Norden',
                NE: 'Nordosten',
                E: 'Osten',
                SE: 'Südosten',
                S: 'Süden',
                SW: 'Südwesten',
                W: 'Westen',
                NW: 'Nordwesten',
                SlightRight: 'leicht rechts',
                Right: 'rechts',
                SharpRight: 'scharf rechts',
                SlightLeft: 'leicht links',
                Left: 'links',
                SharpLeft: 'scharf links',
                Uturn: 'Wenden'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Richtung {dir}', ' auf {road}'],
                'Continue':
                    ['Geradeaus Richtung {dir}', ' auf {road}'],
                'SlightRight':
                    ['Leicht rechts abbiegen', ' auf {road}'],
                'Right':
                    ['Rechts abbiegen', ' auf {road}'],
                'SharpRight':
                    ['Scharf rechts abbiegen', ' auf {road}'],
                'TurnAround':
                    ['Wenden'],
                'SharpLeft':
                    ['Scharf links abbiegen', ' auf {road}'],
                'Left':
                    ['Links abbiegen', ' auf {road}'],
                'SlightLeft':
                    ['Leicht links abbiegen', ' auf {road}'],
                'WaypointReached':
                    ['Zwischenhalt erreicht'],
                'Roundabout':
                    ['Nehmen Sie die {exitStr} Ausfahrt im Kreisverkehr', ' auf {road}'],
                'DestinationReached':
                    ['Sie haben ihr Ziel erreicht'],
                'Fork': ['An der Kreuzung {modifier}', ' auf {road}'],
                'Merge': ['Fahren Sie {modifier} weiter', ' auf {road}'],
                'OnRamp': ['Fahren Sie {modifier} auf die Auffahrt', ' auf {road}'],
                'OffRamp': ['Nehmen Sie die Ausfahrt {modifier}', ' auf {road}'],
                'EndOfRoad': ['Fahren Sie {modifier} am Ende der Straße', ' auf {road}'],
                'Onto': 'auf {road}'
            },
            formatOrder: function (n) {
                return n + '.';
            },
            ui: {
                startPlaceholder: 'Start',
                viaPlaceholder: 'Via {viaNumber}',
                endPlaceholder: 'Ziel'
            }
        },

        'sv': {
            directions: {
                N: 'norr',
                NE: 'nordost',
                E: 'öst',
                SE: 'sydost',
                S: 'syd',
                SW: 'sydväst',
                W: 'väst',
                NW: 'nordväst',
                SlightRight: 'svagt höger',
                Right: 'höger',
                SharpRight: 'skarpt höger',
                SlightLeft: 'svagt vänster',
                Left: 'vänster',
                SharpLeft: 'skarpt vänster',
                Uturn: 'Vänd'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Åk åt {dir}', ' till {road}'],
                'Continue':
                    ['Fortsätt {dir}'],
                'SlightRight':
                    ['Svagt höger', ' till {road}'],
                'Right':
                    ['Sväng höger', ' till {road}'],
                'SharpRight':
                    ['Skarpt höger', ' till {road}'],
                'TurnAround':
                    ['Vänd'],
                'SharpLeft':
                    ['Skarpt vänster', ' till {road}'],
                'Left':
                    ['Sväng vänster', ' till {road}'],
                'SlightLeft':
                    ['Svagt vänster', ' till {road}'],
                'WaypointReached':
                    ['Viapunkt nådd'],
                'Roundabout':
                    ['Tag {exitStr} avfarten i rondellen', ' till {road}'],
                'DestinationReached':
                    ['Framme vid resans mål'],
                'Fork': ['Tag av {modifier}', ' till {road}'],
                'Merge': ['Anslut {modifier} ', ' till {road}'],
                'OnRamp': ['Tag påfarten {modifier}', ' till {road}'],
                'OffRamp': ['Tag avfarten {modifier}', ' till {road}'],
                'EndOfRoad': ['Sväng {modifier} vid vägens slut', ' till {road}'],
                'Onto': 'till {road}'
            },
            formatOrder: function (n) {
                return ['första', 'andra', 'tredje', 'fjärde', 'femte',
                    'sjätte', 'sjunde', 'åttonde', 'nionde', 'tionde'
                    /* Can't possibly be more than ten exits, can there? */][n - 1];
            },
            ui: {
                startPlaceholder: 'Från',
                viaPlaceholder: 'Via {viaNumber}',
                endPlaceholder: 'Till'
            }
        },

        'es': spanish,
        'sp': spanish,

        'nl': {
            directions: {
                N: 'noordelijke',
                NE: 'noordoostelijke',
                E: 'oostelijke',
                SE: 'zuidoostelijke',
                S: 'zuidelijke',
                SW: 'zuidewestelijke',
                W: 'westelijke',
                NW: 'noordwestelijke'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Vertrek in {dir} richting', ' de {road} op'],
                'Continue':
                    ['Ga in {dir} richting', ' de {road} op'],
                'SlightRight':
                    ['Volg de weg naar rechts', ' de {road} op'],
                'Right':
                    ['Ga rechtsaf', ' de {road} op'],
                'SharpRight':
                    ['Ga scherpe bocht naar rechts', ' de {road} op'],
                'TurnAround':
                    ['Keer om'],
                'SharpLeft':
                    ['Ga scherpe bocht naar links', ' de {road} op'],
                'Left':
                    ['Ga linksaf', ' de {road} op'],
                'SlightLeft':
                    ['Volg de weg naar links', ' de {road} op'],
                'WaypointReached':
                    ['Aangekomen bij tussenpunt'],
                'Roundabout':
                    ['Neem de {exitStr} afslag op de rotonde', ' de {road} op'],
                'DestinationReached':
                    ['Aangekomen op eindpunt'],
            },
            formatOrder: function (n) {
                if (n === 1 || n >= 20) {
                    return n + 'ste';
                } else {
                    return n + 'de';
                }
            },
            ui: {
                startPlaceholder: 'Vertrekpunt',
                viaPlaceholder: 'Via {viaNumber}',
                endPlaceholder: 'Bestemming'
            }
        },
        'fr': {
            directions: {
                N: 'nord',
                NE: 'nord-est',
                E: 'est',
                SE: 'sud-est',
                S: 'sud',
                SW: 'sud-ouest',
                W: 'ouest',
                NW: 'nord-ouest'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Tout droit au {dir}', ' sur {road}'],
                'Continue':
                    ['Continuer au {dir}', ' sur {road}'],
                'SlightRight':
                    ['Légèrement à droite', ' sur {road}'],
                'Right':
                    ['A droite', ' sur {road}'],
                'SharpRight':
                    ['Complètement à droite', ' sur {road}'],
                'TurnAround':
                    ['Faire demi-tour'],
                'SharpLeft':
                    ['Complètement à gauche', ' sur {road}'],
                'Left':
                    ['A gauche', ' sur {road}'],
                'SlightLeft':
                    ['Légèrement à gauche', ' sur {road}'],
                'WaypointReached':
                    ['Point d\'étape atteint'],
                'Roundabout':
                    ['Au rond-point, prenez la {exitStr} sortie', ' sur {road}'],
                'DestinationReached':
                    ['Destination atteinte'],
            },
            formatOrder: function (n) {
                return n + 'º';
            },
            ui: {
                startPlaceholder: 'Départ',
                viaPlaceholder: 'Intermédiaire {viaNumber}',
                endPlaceholder: 'Arrivée'
            }
        },
        'it': {
            directions: {
                N: 'nord',
                NE: 'nord-est',
                E: 'est',
                SE: 'sud-est',
                S: 'sud',
                SW: 'sud-ovest',
                W: 'ovest',
                NW: 'nord-ovest'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Dritto verso {dir}', ' su {road}'],
                'Continue':
                    ['Continuare verso {dir}', ' su {road}'],
                'SlightRight':
                    ['Mantenere la destra', ' su {road}'],
                'Right':
                    ['A destra', ' su {road}'],
                'SharpRight':
                    ['Strettamente a destra', ' su {road}'],
                'TurnAround':
                    ['Fare inversione di marcia'],
                'SharpLeft':
                    ['Strettamente a sinistra', ' su {road}'],
                'Left':
                    ['A sinistra', ' sur {road}'],
                'SlightLeft':
                    ['Mantenere la sinistra', ' su {road}'],
                'WaypointReached':
                    ['Punto di passaggio raggiunto'],
                'Roundabout':
                    ['Alla rotonda, prendere la {exitStr} uscita'],
                'DestinationReached':
                    ['Destinazione raggiunta'],
            },
            formatOrder: function (n) {
                return n + 'º';
            },
            ui: {
                startPlaceholder: 'Partenza',
                viaPlaceholder: 'Intermedia {viaNumber}',
                endPlaceholder: 'Destinazione'
            }
        },
        'pt': {
            directions: {
                N: 'norte',
                NE: 'nordeste',
                E: 'leste',
                SE: 'sudeste',
                S: 'sul',
                SW: 'sudoeste',
                W: 'oeste',
                NW: 'noroeste',
                SlightRight: 'curva ligeira a direita',
                Right: 'direita',
                SharpRight: 'curva fechada a direita',
                SlightLeft: 'ligeira a esquerda',
                Left: 'esquerda',
                SharpLeft: 'curva fechada a esquerda',
                Uturn: 'Meia volta'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Siga {dir}', ' na {road}'],
                'Continue':
                    ['Continue {dir}', ' na {road}'],
                'SlightRight':
                    ['Curva ligeira a direita', ' na {road}'],
                'Right':
                    ['Curva a direita', ' na {road}'],
                'SharpRight':
                    ['Curva fechada a direita', ' na {road}'],
                'TurnAround':
                    ['Retorne'],
                'SharpLeft':
                    ['Curva fechada a esquerda', ' na {road}'],
                'Left':
                    ['Curva a esquerda', ' na {road}'],
                'SlightLeft':
                    ['Curva ligueira a esquerda', ' na {road}'],
                'WaypointReached':
                    ['Ponto de interesse atingido'],
                'Roundabout':
                    ['Pegue a {exitStr} saída na rotatória', ' na {road}'],
                'DestinationReached':
                    ['Destino atingido'],
                'Fork': ['Na encruzilhada, vire a {modifier}', ' na {road}'],
                'Merge': ['Entre à {modifier}', ' na {road}'],
                'OnRamp': ['Vire {modifier} na rampa', ' na {road}'],
                'OffRamp': ['Entre na rampa na {modifier}', ' na {road}'],
                'EndOfRoad': ['Vire {modifier} no fim da rua', ' na {road}'],
                'Onto': 'na {road}'
            },
            formatOrder: function (n) {
                return n + 'º';
            },
            ui: {
                startPlaceholder: 'Origem',
                viaPlaceholder: 'Intermédio {viaNumber}',
                endPlaceholder: 'Destino'
            }
        },
        'sk': {
            directions: {
                N: 'sever',
                NE: 'serverovýchod',
                E: 'východ',
                SE: 'juhovýchod',
                S: 'juh',
                SW: 'juhozápad',
                W: 'západ',
                NW: 'serverozápad'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Mierte na {dir}', ' na {road}'],
                'Continue':
                    ['Pokračujte na {dir}', ' na {road}'],
                'SlightRight':
                    ['Mierne doprava', ' na {road}'],
                'Right':
                    ['Doprava', ' na {road}'],
                'SharpRight':
                    ['Prudko doprava', ' na {road}'],
                'TurnAround':
                    ['Otočte sa'],
                'SharpLeft':
                    ['Prudko doľava', ' na {road}'],
                'Left':
                    ['Doľava', ' na {road}'],
                'SlightLeft':
                    ['Mierne doľava', ' na {road}'],
                'WaypointReached':
                    ['Ste v prejazdovom bode.'],
                'Roundabout':
                    ['Odbočte na {exitStr} výjazde', ' na {road}'],
                'DestinationReached':
                    ['Prišli ste do cieľa.'],
            },
            formatOrder: function (n) {
                var i = n % 10 - 1,
                    suffix = ['.', '.', '.'];

                return suffix[i] ? n + suffix[i] : n + '.';
            },
            ui: {
                startPlaceholder: 'Začiatok',
                viaPlaceholder: 'Cez {viaNumber}',
                endPlaceholder: 'Koniec'
            }
        },
        'el': {
            directions: {
                N: 'βόρεια',
                NE: 'βορειοανατολικά',
                E: 'ανατολικά',
                SE: 'νοτιοανατολικά',
                S: 'νότια',
                SW: 'νοτιοδυτικά',
                W: 'δυτικά',
                NW: 'βορειοδυτικά'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Κατευθυνθείτε {dir}', ' στην {road}'],
                'Continue':
                    ['Συνεχίστε {dir}', ' στην {road}'],
                'SlightRight':
                    ['Ελαφρώς δεξιά', ' στην {road}'],
                'Right':
                    ['Δεξιά', ' στην {road}'],
                'SharpRight':
                    ['Απότομη δεξιά στροφή', ' στην {road}'],
                'TurnAround':
                    ['Κάντε αναστροφή'],
                'SharpLeft':
                    ['Απότομη αριστερή στροφή', ' στην {road}'],
                'Left':
                    ['Αριστερά', ' στην {road}'],
                'SlightLeft':
                    ['Ελαφρώς αριστερά', ' στην {road}'],
                'WaypointReached':
                    ['Φτάσατε στο σημείο αναφοράς'],
                'Roundabout':
                    ['Ακολουθήστε την {exitStr} έξοδο στο κυκλικό κόμβο', ' στην {road}'],
                'DestinationReached':
                    ['Φτάσατε στον προορισμό σας'],
            },
            formatOrder: function (n) {
                return n + 'º';
            },
            ui: {
                startPlaceholder: 'Αφετηρία',
                viaPlaceholder: 'μέσω {viaNumber}',
                endPlaceholder: 'Προορισμός'
            }
        },
        'ca': {
            directions: {
                N: 'nord',
                NE: 'nord-est',
                E: 'est',
                SE: 'sud-est',
                S: 'sud',
                SW: 'sud-oest',
                W: 'oest',
                NW: 'nord-oest',
                SlightRight: 'lleu gir a la dreta',
                Right: 'dreta',
                SharpRight: 'gir pronunciat a la dreta',
                SlightLeft: 'gir pronunciat a l\'esquerra',
                Left: 'esquerra',
                SharpLeft: 'lleu gir a l\'esquerra',
                Uturn: 'mitja volta'
            },
            instructions: {
                'Head':
                    ['Recte {dir}', ' sobre {road}'],
                'Continue':
                    ['Continuar {dir}'],
                'TurnAround':
                    ['Donar la volta'],
                'WaypointReached':
                    ['Ha arribat a un punt del camí'],
                'Roundabout':
                    ['Agafar {exitStr} sortida a la rotonda', ' a {road}'],
                'DestinationReached':
                    ['Arribada al destí'],
                'Fork': ['A la cruïlla gira a la {modifier}', ' cap a {road}'],
                'Merge': ['Incorpora\'t {modifier}', ' a {road}'],
                'OnRamp': ['Gira {modifier} a la sortida', ' cap a {road}'],
                'OffRamp': ['Pren la sortida {modifier}', ' cap a {road}'],
                'EndOfRoad': ['Gira {modifier} al final de la carretera', ' cap a {road}'],
                'Onto': 'cap a {road}'
            },
            formatOrder: function (n) {
                return n + 'º';
            },
            ui: {
                startPlaceholder: 'Origen',
                viaPlaceholder: 'Via {viaNumber}',
                endPlaceholder: 'Destí'
            },
            units: {
                meters: 'm',
                kilometers: 'km',
                yards: 'yd',
                miles: 'mi',
                hours: 'h',
                minutes: 'min',
                seconds: 's'
            }
        },
        'ru': {
            directions: {
                N: 'север',
                NE: 'северовосток',
                E: 'восток',
                SE: 'юговосток',
                S: 'юг',
                SW: 'югозапад',
                W: 'запад',
                NW: 'северозапад',
                SlightRight: 'плавно направо',
                Right: 'направо',
                SharpRight: 'резко направо',
                SlightLeft: 'плавно налево',
                Left: 'налево',
                SharpLeft: 'резко налево',
                Uturn: 'развернуться'
            },
            instructions: {
                'Head':
                    ['Начать движение на {dir}', ' по {road}'],
                'Continue':
                    ['Продолжать движение на {dir}', ' по {road}'],
                'SlightRight':
                    ['Плавный поворот направо', ' на {road}'],
                'Right':
                    ['Направо', ' на {road}'],
                'SharpRight':
                    ['Резкий поворот направо', ' на {road}'],
                'TurnAround':
                    ['Развернуться'],
                'SharpLeft':
                    ['Резкий поворот налево', ' на {road}'],
                'Left':
                    ['Поворот налево', ' на {road}'],
                'SlightLeft':
                    ['Плавный поворот налево', ' на {road}'],
                'WaypointReached':
                    ['Точка достигнута'],
                'Roundabout':
                    ['{exitStr} съезд с кольца', ' на {road}'],
                'DestinationReached':
                    ['Окончание маршрута'],
                'Fork': ['На развилке поверните {modifier}', ' на {road}'],
                'Merge': ['Перестройтесь {modifier}', ' на {road}'],
                'OnRamp': ['Поверните {modifier} на съезд', ' на {road}'],
                'OffRamp': ['Съезжайте на {modifier}', ' на {road}'],
                'EndOfRoad': ['Поверните {modifier} в конце дороги', ' на {road}'],
                'Onto': 'на {road}'
            },
            formatOrder: function (n) {
                return n + '-й';
            },
            ui: {
                startPlaceholder: 'Начало',
                viaPlaceholder: 'Через {viaNumber}',
                endPlaceholder: 'Конец'
            },
            units: {
                meters: 'м',
                kilometers: 'км',
                yards: 'ярд',
                miles: 'ми',
                hours: 'ч',
                minutes: 'м',
                seconds: 'с'
            }
        },

        'pl': {
            directions: {
                N: 'północ',
                NE: 'północny wschód',
                E: 'wschód',
                SE: 'południowy wschód',
                S: 'południe',
                SW: 'południowy zachód',
                W: 'zachód',
                NW: 'północny zachód',
                SlightRight: 'lekko w prawo',
                Right: 'w prawo',
                SharpRight: 'ostro w prawo',
                SlightLeft: 'lekko w lewo',
                Left: 'w lewo',
                SharpLeft: 'ostro w lewo',
                Uturn: 'zawróć'
            },
            instructions: {
                // instruction, postfix if the road is named
                'Head':
                    ['Kieruj się na {dir}', ' na {road}'],
                'Continue':
                    ['Jedź dalej przez {dir}'],
                'TurnAround':
                    ['Zawróć'],
                'WaypointReached':
                    ['Punkt pośredni'],
                'Roundabout':
                    ['Wyjedź {exitStr} zjazdem na rondzie', ' na {road}'],
                'DestinationReached':
                    ['Dojechano do miejsca docelowego'],
                'Fork': ['Na rozwidleniu {modifier}', ' na {road}'],
                'Merge': ['Zjedź {modifier}', ' na {road}'],
                'OnRamp': ['Wjazd {modifier}', ' na {road}'],
                'OffRamp': ['Zjazd {modifier}', ' na {road}'],
                'EndOfRoad': ['Skręć {modifier} na końcu drogi', ' na {road}'],
                'Onto': 'na {road}'
            },
            formatOrder: function (n) {
                return n + '.';
            },
            ui: {
                startPlaceholder: 'Początek',
                viaPlaceholder: 'Przez {viaNumber}',
                endPlaceholder: 'Koniec'
            },
            units: {
                meters: 'm',
                kilometers: 'km',
                yards: 'yd',
                miles: 'mi',
                hours: 'godz',
                minutes: 'min',
                seconds: 's'
            }
        }
    });
})();
