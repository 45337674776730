import React, {useContext} from 'react';
import Notification from "./Notification";
import Profile from "./Profile";
import useVehicles from "../_hook/useVehicles";
import {AppContext} from "../_context/AppState";

const MapApp: React.FC = () => {
    const {dispatch, user} = useContext(AppContext);

    useVehicles();

    return <React.Fragment>
        {user ? <Notification/> : null}
        {user ? <Profile/> : null}
        <div id={"mapId"}></div>
    </React.Fragment>
}

export default MapApp;