var L = require('leaflet'),
    Control = require('./control'),
    Itinerary = require('./itinerary'),
    Line = require('./line'),
    OSRMv1 = require('./osrm-v1'),
    Plan = require('./plan'),
    Waypoint = require('./waypoint'),
    Autocomplete = require('./autocomplete'),
    Formatter = require('./formatter'),
    GeocoderElement = require('./geocoder-element'),
    Localization = require('./localization'),
    ItineraryBuilder = require('./itinerary-builder'),
    Mapbox = require('./mapbox'),
    ErrorControl = require('./error-control');

L.routing = {
    control: function (options) {
        return new Control(options);
    },
    itinerary: function (options) {
        return Itinerary(options);
    },
    line: function (route, options) {
        return new Line(route, options);
    },
    plan: function (waypoints, options) {
        return new Plan(waypoints, options);
    },
    waypoint: function (latLng, name, options) {
        return new Waypoint(latLng, name, options);
    },
    osrmv1: function (options) {
        return new OSRMv1(options);
    },
    localization: function (options) {
        return new Localization(options);
    },
    formatter: function (options) {
        return new Formatter(options);
    },
    geocoderElement: function (wp, i, nWps, plan) {
        return new L.Routing.GeocoderElement(wp, i, nWps, plan);
    },
    itineraryBuilder: function (options) {
        return new ItineraryBuilder(options);
    },
    mapbox: function (accessToken, options) {
        return new Mapbox(accessToken, options);
    },
    errorControl: function (routingControl, options) {
        return new ErrorControl(routingControl, options);
    },
    autocomplete: function (elem, callback, context, options) {
        return new Autocomplete(elem, callback, context, options);
    }
};

export default L.Routing = {
    Control: Control,
    Itinerary: Itinerary,
    Line: Line,
    OSRMv1: OSRMv1,
    Plan: Plan,
    Waypoint: Waypoint,
    Autocomplete: Autocomplete,
    GeocoderElement: GeocoderElement,
    Localization: Localization,
    Formatter: Formatter,
    ItineraryBuilder: ItineraryBuilder,

    // Legacy; remove these in next major release
    control: L.routing.control,
    itinerary: L.routing.itinerary,
    line: L.routing.line,
    plan: L.routing.plan,
    waypoint: L.routing.waypoint,
    osrmv1: L.routing.osrmv1,
    geocoderElement: L.routing.geocoderElement,
    mapbox: L.routing.mapbox,
    errorControl: L.routing.errorControl,
};
