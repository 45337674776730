import React, {useContext, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AppContext} from "../_context/AppState";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AuthService from "../_services/auth.service";
import Avatar from '@material-ui/core/Avatar';
import {deepOrange} from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
        },
        profile: {
            position: "absolute",
            zIndex: 11111,
            right: 5,
        },
        typography: {
            padding: theme.spacing(2),
        },
        notiContent: {
            width: 328,
            maxHeight: 448,
            backgroundColor: theme.palette.background.paper,
        },
        signOut: {
            margin: 10,
            textAlign: 'center',
        },
        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: '10px',
            fontWeight: 'bold',
        },
    }),
);

interface State {
    startingpoint: string;
    destination: string;
}

const Profile: React.FC = () => {
    const classes = useStyles();
    const {user, setUser} = useContext(AppContext)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        // TODO: need improve struct code when signout
        setUser(null);
        AuthService.logout()
        //history.push("/sign-in")
        // Hardcode for reload then lose all state
        window.location.reload();
    }

    const openNoti = Boolean(anchorEl);
    const ipPopNoti = openNoti ? 'simple-popover' : undefined;

    return <div className={classes.profile}>
        <IconButton aria-label="show profile" color="inherit" onClick={handleClick}>
            <Avatar className={classes.orange}>{user.username.charAt(0).toUpperCase()}</Avatar>
        </IconButton>
        <Popover
            id={ipPopNoti}
            open={openNoti}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List component="nav" dense={true} className={classes.notiContent}>
                <ListItem button>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={`Username: ${user.username}`}/>
                </ListItem>
            </List>
            <Divider/>
            <div className={classes.signOut}>
                <Button size="small" variant="outlined" color="primary"
                        onClick={handleSignOut}>Sign out</Button></div>
        </Popover>
    </div>
}

export default Profile;