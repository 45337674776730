import L from 'leaflet';
import {getJSON} from '../util';

export var MapQuest = L.Class.extend({
    options: {
        serviceUrl: 'https://www.mapquestapi.com/geocoding/v1'
    },

    initialize: function (key, options) {
        // MapQuest seems to provide URI encoded API keys,
        // so to avoid encoding them twice, we decode them here
        this._key = decodeURIComponent(key);

        L.Util.setOptions(this, options);
    },

    _formatName: function () {
        var r = [],
            i;
        for (i = 0; i < arguments.length; i++) {
            if (arguments[i]) {
                r.push(arguments[i]);
            }
        }

        return r.join(', ');
    },

    geocode: function (query, cb, context) {
        getJSON(
            this.options.serviceUrl + '/address',
            {
                key: this._key,
                location: query,
                limit: 5,
                outFormat: 'json'
            },
            L.bind(function (data) {
                var results = [],
                    loc,
                    latLng;
                if (data.results && data.results[0].locations) {
                    for (var i = data.results[0].locations.length - 1; i >= 0; i--) {
                        loc = data.results[0].locations[i];
                        latLng = L.latLng(loc.latLng);
                        results[i] = {
                            name: this._formatName(loc.street, loc.adminArea4, loc.adminArea3, loc.adminArea1),
                            bbox: L.latLngBounds(latLng, latLng),
                            center: latLng
                        };
                    }
                }

                cb.call(context, results);
            }, this)
        );
    },

    reverse: function (location, scale, cb, context) {
        getJSON(
            this.options.serviceUrl + '/reverse',
            {
                key: this._key,
                location: location.lat + ',' + location.lng,
                outputFormat: 'json'
            },
            L.bind(function (data) {
                var results = [],
                    loc,
                    latLng;
                if (data.results && data.results[0].locations) {
                    for (var i = data.results[0].locations.length - 1; i >= 0; i--) {
                        loc = data.results[0].locations[i];
                        latLng = L.latLng(loc.latLng);
                        results[i] = {
                            name: this._formatName(loc.street, loc.adminArea4, loc.adminArea3, loc.adminArea1),
                            bbox: L.latLngBounds(latLng, latLng),
                            center: latLng
                        };
                    }
                }

                cb.call(context, results);
            }, this)
        );
    }
});

export function mapQuest(key, options) {
    return new MapQuest(key, options);
}
